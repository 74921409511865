<template>
    <custom-table
        :title="$t('pages.developer.videoManagement.converter.title')"
        :subTitle="$t('pages.developer.videoManagement.converter.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:progress="{ row: record }">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="record.progress_percentage"/>
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="sprintf('/developer/video-management/converter/%d/detail', [record.id])" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </router-link>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_converter" ref="converterModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="converterForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.converter.cols.converterType') }}</label>
                                <el-form-item prop="convert_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.convert_type" :placeholder="$t('common.chooseSelect')" class="w-100">
                                        <el-option v-for="(type, typeIndex) in converterTypes" :key="typeIndex" :value="type" :label="type"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.converter.cols.manager') }}</label>
                                <el-form-item prop="admin_id">
                                    <el-select v-model="form.data.admin_id" :placeholder="$t('common.chooseSelect')" class="w-100">
                                        <el-option v-for="(admin, adminIndex) in adminOptions" :key="adminIndex" :value="admin.id" :label="admin.full_name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.converter.cols.selectorType') }}</label>
                                <el-form-item prop="selector_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.selector_type" :placeholder="$t('common.chooseSelect')" class="w-100">
                                        <el-option v-for="(selectorType, selectorTypeIndex) in selectorTypes" :key="selectorTypeIndex" :value="selectorType.value" :label="$t('pages.developer.videoManagement.converter.selectorType.' + (selectorType.type))"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.developer.videoManagement.converter.cols.converterCount') }}</label>
                                <el-form-item prop="max_count" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.max_count" :min="1"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("common.total"),
                    key: "items_count"
                },
                {
                    name: this.$t("common.inProcess"),
                    key: "converting_items_count"
                },
                {
                    name: this.$t("common.pending"),
                    key: "pending_items_count"
                },
                {
                    name: this.$t("common.completed"),
                    key: "converted_items_count"
                },
                {
                    name: this.$t("common.progress"),
                    scopedSlots: {customRender: "progress"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            converterTypes: ['HLS'],
            selectorTypes: [{type: "random", value: "random"},{type: "created_asc", value: "created:asc"},{type: "created_desc", value: "created:desc"}]
        }
    },
    computed: {
        table() {
            return this.$store.state.developer.videoManagement.converter.table;
        },
        admins() {
            return this.$store.state.developer.customer.table.data;
        },
        adminOptions(){
            let admins = this.cloneData(this.admins);

            admins.unshift({
                id: null,
                full_name: this.$t('common.viewAll')
            });

            return admins;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.developerManagement.videoManagement.converter"), [this.$t("menu.developerManagement.title"), this.$t("menu.developerManagement.videoManagement.title")]);
        this.$store.dispatch('developer/videoManagement/converter/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
        this.$store.dispatch('developer/customer/get', {
            page: { pageSize: 9999 }
        });
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {
                admin_id: null,
                max_count: 5
            };
            this.form.title = this.$t("pages.developer.videoManagement.converter.new");
            this.showModal(this.$refs.converterModal);
        },
        onSubmit(){
            this.$refs.converterForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoint.get('video_management_converter', 'developer'), this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.converterModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch(error => {
                        error.response.data.message = this.$t('pages.developer.videoManagement.converter.responseMessages.' + error.response.data.message, error.response.data.message);

                        this.onResponseFailure(error.response.data, () => {
                            this.form.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("developer/videoManagement/converter/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("developer/videoManagement/converter/get", {
                page: pagination,
                filterData: filterData
            });
        },
    }
}
</script>

<style>
</style>